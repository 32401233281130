import {inject,bindingMode,bindable,customAttribute,Aurelia } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import { ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { DOM } from 'aurelia-framework';
import flatpickr from 'flatpickr';
import { Router } from 'aurelia-router';
import {LoginService} from "../../../shared/services/loginservice";

@inject(JwtService, ProfileService, ValidationControllerFactory, Aurelia,Router,LoginService)
export class Userdefined {

   @bindable dateValue = '';
   @bindable name = '';

   inputFields = [];
   selectedType = null;
   textareas = [];
   InputContainer = null;
   fieldsFetched = 0;
   selectedDate = '';
   UserDefinedFormErrors = [];
   FieldSuccessmessage = false;
   FieldDeletmessage = false;

   constructor(JwtService, ProfileService, ValidationControllerFactory, aurelia,router,loginService) {
      this.jwtService = JwtService;
      this.profileService = ProfileService;
      this.aurelia = aurelia;
      this.validationController = ValidationControllerFactory.createForCurrentScope();
      this.router = router;
       this.loginService = loginService;
      this.Employeeid = this.jwtService.getEmployeeId();
   }

   async attached(params) {


      const id = this.router.currentInstruction.queryParams.id;
            console.log(id);
            if(id != null && id !='' && id !=undefined)
            {
               this.Employeeid=id;
            }
            else{
               this.Employeeid=this.jwtService.getEmployeeId();      
            }

      console.log(this.selectedDate);
      const UserDefinedFieldsData = await this.profileService.GetUserDefinedFields(this.Employeeid);
      this.userDefiedData = await UserDefinedFieldsData;
      this.userDefiedData.filter(response => {
         console.log(response);
         switch (response.FieldTypeText) {
            case "text":
               const containerDiv = document.createElement('div');
               const label = document.createElement('label');
               label.textContent = response.FieldName;
               if (response.IsMandatory) {
                  ValidationRules.ensure(response.FieldName).required().on(this.userDefiedData);
                  const mandatorySymbol = document.createElement('span');
                  mandatorySymbol.textContent = '*';
                  mandatorySymbol.style.color = 'red';
                  label.appendChild(mandatorySymbol);
                  // Add validation message span element
                  const validationMessage = document.createElement('span');
                  validationMessage.className = 'validation-message';
                  containerDiv.appendChild(validationMessage);
                  // Add validation rule for the field
                  this.validationController.addObject(this.userDefiedData, validationRules => {
                     validationRules.ensure(response.FieldName)
                        .displayName(response.FieldName)
                        .required()
                        .withMessage(`${response.FieldName} is required.`);
                  });
               }
               containerDiv.appendChild(label);
               const Textinput = document.createElement('input');
               Textinput.type = 'text';
               Textinput.id = response.ReferenceUDFID;
               //Textinput.placeholder = '';
               Textinput.value = response.Value;
               this.textValue = response.Value;
               Textinput.addEventListener('change', (event) => {
                  this.textValue = event.target.value;
                  console.log(`Field ${response.FieldTypeID} value: ${this.textValue}`);
                  // You can store or process the value here
               });
               containerDiv.appendChild(Textinput);
               this.InputContainer.appendChild(containerDiv);
               break;
            case "textarea":
               const containerTextarea = document.createElement('div');
               const areaLabel = document.createElement('label');
               areaLabel.textContent = response.FieldName;

               if (response.IsMandatory) {
                  ValidationRules.ensure(response.FieldName).required().on(this.userDefiedData);
                  const mandatorySymbol = document.createElement('span');
                  mandatorySymbol.textContent = '*';
                  mandatorySymbol.style.color = 'red';
                  areaLabel.appendChild(mandatorySymbol);
               }
               containerTextarea.appendChild(areaLabel);
               const textInput = document.createElement('textarea');
               textInput.rows = 5;
               textInput.cols = 20;
               textInput.id = response.ReferenceUDFID;
               //textInput.placeholder = 'Enter comments';
               textInput.value = response.Value;
               this.areaValue = response.Value;
               textInput.ref = response.FieldName.replace(' ', '');
               textInput.addEventListener('change', (event) => {
                  this.areaValue = event.target.value;
                  console.log(`Field ${response.FieldTypeID} value: ${this.areaValue}`);
                  // You can store or process the value here
               });
               containerTextarea.appendChild(textInput);
               this.InputContainer.appendChild(containerTextarea);
               this.textareas.push(textInput);
               break;
            case "date":
               const containerDate = document.createElement('div');
               const labelDate = document.createElement('label');
               labelDate.textContent = response.FieldName;
               if (response.IsMandatory) {
                  ValidationRules.ensure(response.FieldName).required().on(this.userDefiedData);
                  const mandatorySymbol = document.createElement('span');
                  mandatorySymbol.textContent = '*';
                  mandatorySymbol.style.color = 'red';
                  labelDate.appendChild(mandatorySymbol);
               }
               containerDate.appendChild(labelDate);
               const dateInput = document.createElement('input');
               dateInput.type = 'text';
               dateInput.id = response.ReferenceUDFID;
               dateInput.value = response.Value;
               this.selectedDate = response.Value;
               //dateInput.placeholder = 'Select date';
               dateInput.ref = response.FieldName.replace(' ', '');
               containerDate.appendChild(dateInput);
               this.InputContainer.appendChild(containerDate);
               //flatpickr(dateInput);
               const datePicker = flatpickr(dateInput, {
                  closeOnSelect: true,
                  dateFormat: 'd-m-Y',
                  minDate: null,
                  maxDate: null,
                  defaultDate: '',
                  enableTime: false,
                  enableSeconds: false,
                  time_24hr: false,
                  minuteIncrement: 1,
                  hourIncrement: 1,
                  timeFormat: 'h:i K',
                  onClose: function (selectedDates, dateStr, instance) {
                     this.selectedDate = dateStr;
                     //console.log(this.selectedDate);
                  }
               });
               dateInput.addEventListener('change', (event) => {
                this.selectedDate = event.target.value;
             });
               break;

            case "select":
               const containerSelect = document.createElement('div');
               const labelSelect = document.createElement('label');
               labelSelect.textContent = response.FieldName;

               if (response.IsMandatory) {
                  ValidationRules.ensure(response.FieldName).required().on(this.userDefiedData);
                  const mandatorySymbol = document.createElement('span');
                  mandatorySymbol.textContent = '*';
                  mandatorySymbol.style.color = 'red';
                  labelSelect.appendChild(mandatorySymbol);
               }

               containerSelect.appendChild(labelSelect);
               const selectInput = document.createElement('select');
               selectInput.id = response.ReferenceUDFID;
               const selectOptions = response.OptionList;
               selectOptions.forEach((option) => {
                  const optionElement = document.createElement('option');
                  optionElement.value = option.UDFOptionID;
                  optionElement.text = option.Option;
                  selectInput.appendChild(optionElement);
                  if (response.Value == option.UDFOptionID) {
                     optionElement.selected = option.Option;
                  }
               });
               containerSelect.appendChild(selectInput);
               this.InputContainer.appendChild(containerSelect);

               const selectElement = containerSelect.querySelector('select');
               selectElement.addEventListener('change', () => {
                  // Get the selected value
                  const selectedValue = selectElement.value;
                  this.SelectValue = selectedValue;
                  console.log(`Field ${response.FieldTypeID} value: ${selectedValue}`);
                  // You can store or process the value here
               });
               break;

            case "radio":
               const containerRadio = document.createElement('div');
               const labelRadio = document.createElement('label');
               labelRadio.textContent = response.FieldName;

               if (response.IsMandatory) {
                  ValidationRules.ensure(response.FieldName).required().on(this.userDefiedData);
                  const mandatorySymbol = document.createElement('span');
                  mandatorySymbol.textContent = '*';
                  mandatorySymbol.style.color = 'red';
                  labelRadio.appendChild(mandatorySymbol);
               }

               containerRadio.appendChild(labelRadio);
               const radioOptions = response.OptionList;
               radioOptions.forEach((option) => {
                  const radioLabel = document.createElement('label');
                  const radioInput = document.createElement('input');
                  radioInput.type = 'radio';
                  radioInput.name = 'radioOptions';
                  radioInput.value = option.UDFOptionID;
                  radioInput.id = response.ReferenceUDFID;
                  radioLabel.textContent = option.Option;
                  radioLabel.appendChild(radioInput);
                  if (response.Value == option.UDFOptionID) {
                    //optionElement.selected = option.Option;
                    radioInput.checked = true;
                 }
                  containerRadio.appendChild(radioLabel);
               });
               this.InputContainer.appendChild(containerRadio);
               break;

            case "checkbox":
               const containerCheckbox = document.createElement('div');
               const labelCheckbox = document.createElement('label');
               labelCheckbox.textContent = response.FieldName;

               if (response.IsMandatory) {
                  ValidationRules.ensure(response.FieldName).required().on(this.userDefiedData);
                  const mandatorySymbol = document.createElement('span');
                  mandatorySymbol.textContent = '*';
                  mandatorySymbol.style.color = 'red';
                  labelCheckbox.appendChild(mandatorySymbol);
               }

               containerCheckbox.appendChild(labelCheckbox);
               const checkboxOptions = response.OptionList;
               checkboxOptions.forEach((option) => {
                  const checkboxLabel = document.createElement('label');
                  const checkboxInput = document.createElement('input');
                  checkboxInput.type = 'checkbox';
                  checkboxInput.name = 'checkboxOptions';
                  checkboxInput.value = option.UDFOptionID;
                  checkboxInput.id = response.ReferenceUDFID;
                  checkboxLabel.textContent = option.Option;
                  checkboxLabel.appendChild(checkboxInput);
                  if (option.IsSelected) {
                     checkboxInput.checked = true;
                  }
                  containerCheckbox.appendChild(checkboxLabel);
               });
               this.InputContainer.appendChild(containerCheckbox);
               break;

            default:
               console.log("Invalid FieldTypeText");
               break;
         }
      });
      // Create a button container
      const buttonContainer = document.createElement('div');
      buttonContainer.className = 'button-container';
      this.InputContainer.appendChild(buttonContainer);

      // Create a submit button
      const submitButton = document.createElement('button');
      submitButton.textContent = 'Save';
      submitButton.type = 'submit';
      buttonContainer.appendChild(submitButton);

      const cancelButton = document.createElement('button');
      cancelButton.textContent = 'Cancel';
      cancelButton.type = 'button';
      buttonContainer.appendChild(cancelButton);

      cancelButton.addEventListener('click', async () => {
        const UserDefinedFieldsData = await this.profileService.GetUserDefinedFields(this.Employeeid);
        this.userDefiedData = await UserDefinedFieldsData;
      });

      const formElement = document.querySelector('.user-defined');
      formElement.addEventListener('submit', event => {
         event.preventDefault();

         const userDefinedList = [];
         this.isFormValid = true; // Flag to track form validity

         // Loop through all dynamically generated fields
         this.userDefiedData.forEach(response => {
            const fieldValues = {};
            switch (response.FieldTypeText) {
               case "text":   
               this.textError = 'User defined field(s) are required.';
                  fieldValues.EmployeeID = this.Employeeid;
                  fieldValues.FieldTypeID = response.FieldTypeID;
                  fieldValues.Value = this.textValue;
                  fieldValues.ReferenceUDFID = response.ReferenceUDFID;
                  fieldValues.Value = response.IsMandatory === true &&  (this.textValue == null || this.textValue == '') ? this.isFormValid = false : this.textValue;
                  userDefinedList.push(fieldValues);
                  break;
               case "textarea":
                  fieldValues.EmployeeID = this.Employeeid;
                  fieldValues.FieldTypeID = response.FieldTypeID;
                  //fieldValues.Value = this.areaValue;
                  fieldValues.ReferenceUDFID = response.ReferenceUDFID;
                  //fieldValues.Value = response.IsMandatory === true && (this.areaValue == null || this.areaValue == '') ? console.log("area Error") : (response.IsMandatory === false && this.areaValue ? this.areaValue : this.areaValue);
                  fieldValues.Value = response.IsMandatory === true &&  (this.areaValue == null || this.areaValue == '') ? this.isFormValid = false : this.areaValue;
                  userDefinedList.push(fieldValues);
                  break;
               case "date":
                  fieldValues.EmployeeID = this.Employeeid;
                  fieldValues.FieldTypeID = response.FieldTypeID;
                  //fieldValues.Value = ''; // Use the selectedDate value
                  //fieldValues.Value = response.IsMandatory === true &&  this.selectedDate == null ? console.log("area Error") : (response.IsMandatory === false && this.selectedDate ? this.selectedDate : this.selectedDate);
                  fieldValues.Value = response.IsMandatory === true &&  (this.selectedDate == null || this.selectedDate == '') ? this.isFormValid = false : this.selectedDate;
                  fieldValues.ReferenceUDFID = response.ReferenceUDFID;
                  userDefinedList.push(fieldValues);
                  break;
               case "select":
                  fieldValues.EmployeeID = this.Employeeid;
                  fieldValues.FieldTypeID = response.FieldTypeID;
                  //fieldValues.Value = this.SelectValue;
                  //fieldValues.Value = response.IsMandatory === true &&  this.SelectValue == null ? console.log("Select Error") : (response.IsMandatory === false && this.SelectValue ? this.SelectValue : this.SelectValue);
                  fieldValues.Value = response.IsMandatory === true &&  (this.SelectValue == null || this.SelectValue == '') ? this.isFormValid = false : this.SelectValue;
                  fieldValues.ReferenceUDFID = response.ReferenceUDFID;
                  userDefinedList.push(fieldValues);
                  break;
               case "radio":
                  const radioElement = document.querySelector(`input[name="radioOptions"]:checked`);
                  fieldValues.EmployeeID = this.Employeeid;
                  fieldValues.FieldTypeID = response.FieldTypeID;
                  fieldValues.Value = radioElement ? radioElement.value : null;
                  //fieldValues.Value = response.IsMandatory === true && !radioElement ? console.log('radio error') :(response.IsMandatory === false && radioElement ? radioElement.value : radioElement.value);
                  //fieldValues.Value = this.getSelectedRadioOption(response.FieldName); // Call helper method to get selected radio option
                  fieldValues.ReferenceUDFID = response.ReferenceUDFID;
                  userDefinedList.push(fieldValues);
                  break;
               case "checkbox":
                  const checkboxElements = document.querySelectorAll(`input[name="checkboxOptions"]:checked`);
                  fieldValues.EmployeeID = this.Employeeid;
                  fieldValues.FieldTypeID = response.FieldTypeID;
                  fieldValues.Option = checkboxElements ? Array.from(checkboxElements).map(checkbox => checkbox.value) : [];
                  //fieldValues.Option = response.IsMandatory && checkboxElements ? Array.from(checkboxElements).map(checkbox => checkbox.value) :console.log('Check errors');
                  //fieldValues.Value = this.getSelectedCheckboxOptions(response.FieldName); // Call helper method to get selected checkbox options
                  fieldValues.ReferenceUDFID = response.ReferenceUDFID;
                  userDefinedList.push(fieldValues);
                  break;
               default:
                  console.log("Invalid FieldTypeText");
                  break;
            }
         });
         if (this.isFormValid) {
         const userdefinedmodel = {
            UserDefinedList: userDefinedList,
            EmployeeID: this.Employeeid,
         };
         console.log(userdefinedmodel);
         this.profileService.SaveUserDefinedFields(userdefinedmodel).then(response => {
            console.log(response);
            this.Fieldmessage = "User defined fields updated successfully";
            //this.Fieldmessage = this.textError;
            this.FieldSuccessmessage = true;
            setTimeout(() => {
              this.Fieldmessage = null
            }, 3000);
         });
        }else {
          this.FieldErrormessage = 'User defined field(s) are required.';
          this.FieldDeletmessage = true;
            setTimeout(() => {
              this.FieldErrormessage = null
            }, 3000);
          console.log('Form has empty mandatory fields. Please fill them.');
       }
      });
   }

   getSelectedOption(fieldName) {
      const selectElement = this.InputContainer.querySelector(`select[name='${fieldName.replace(' ', '')}']`);
      return selectElement.value;
   }

   getSelectedRadioOption(fieldName) {
      const radioElement = this.InputContainer.querySelector(`input[name='${fieldName.replace(' ', '')}']:checked`);
      if (radioElement) {
         return radioElement.value;
      }
      return null;
   }

   getSelectedCheckboxOptions(fieldName) {
      const checkboxElements = this.InputContainer.querySelectorAll(`input[name='${fieldName.replace(' ', '')}']:checked`);
      const selectedOptions = Array.from(checkboxElements).map(element => element.value);
      return selectedOptions;
   }

}
