import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import { Addworkexperience } from './addworkexperience/addworkexperience';
import { Editworkexperience } from './editworkexperience/editworkexperience';
import { AlertpopupComponent } from '../../alertpopup/alertpopupcomponent';
import Scrollbar from 'smooth-scrollbar';
import {config} from '../../../shared/services/config';
import { Router } from 'aurelia-router';
import {LoginService} from "../../../shared/services/loginservice";
@inject(ProfileService, JwtService, DialogService,Router,LoginService)
export class Workexperience {

   ExperienceSuccessmessage = false;
   ExperienceDeletmessage = false;
   ExperienceUpdatemessage = false;
   pageSize = config.grid_PazeSize;

   constructor(ProfileService, JwtService, DialogService,router,loginService) {
      this.profileService = ProfileService;
      this.jwtService = JwtService;
      this.dialogService = DialogService;
       this.router = router;
       this.loginService = loginService;
   }

   activate() {
      this.workExperience = this.RefreshExperience();
      this.workExperience = this.RefreshEditExperience();    
   }

   async bind() {
      setTimeout(() => {
         Scrollbar.init(document.querySelector('.experience-scrollbar'));
      }, 100)
   }

   async attached() {

      const empid = this.jwtService.getEmployeeId();
      document.addEventListener('refreshData', this.RefreshExperience);
      document.addEventListener('refreshEditData', this.RefreshEditExperience);

      const WorkExperienceData = await this.profileService.GetWorkExperience(empid);
      this.workExperience = WorkExperienceData;
   }

   addWorkExperience() {
      this.dialogService.open({
         viewModel: Addworkexperience
      });
   }

   RefreshExperience = () => {
      const empid = this.jwtService.getEmployeeId();
      // Call the API or refresh the data in GridComponent
      this.profileService.GetWorkExperience(empid).then(data => {
         this.workExperience = data;
         this.Expmessage = "Work experience added successfully";
         this.ExperienceSuccessmessage = true;
         this.ExperienceUpdatemessage = false;
         setTimeout(() => {
            this.Expmessage = null
         }, 3000);
      })

   };
   editWorkExperience(editData) {
      this.dialogService.open({
         viewModel: Editworkexperience,
         model: editData
      });
   }

   RefreshEditExperience = () => {
      const empid = this.jwtService.getEmployeeId();
      // Call the API or refresh the data in GridComponent
      this.profileService.GetWorkExperience(empid).then(data => {
         this.workExperience = data;
         this.ExpUpdatemessage = "Work experience updated successfully";
         this.ExperienceUpdatemessage = true;
         this.ExperienceSuccessmessage = false;
         setTimeout(() => {
            this.ExpUpdatemessage = null
         }, 3000);
      })

   };

   async deleteWorkExperience(data) {
      const empid = this.jwtService.getEmployeeId();
      const ExperienceID = data.PersonWorkExperienceID;

      const result = await this.dialogService.open({
         viewModel: AlertpopupComponent,
         model: "",
      }).whenClosed(response => response);

      if (result.wasCancelled) {
         return;
      }
      await this.profileService.DeleteWorkExperience(ExperienceID, empid);
      const employeeExperience = await this.profileService.GetWorkExperience(empid);
      this.ExpDeletmessage = "Work experience deleted successfully";
      this.ExperienceDeletmessage = true;
      setTimeout(() => {
         this.ExpDeletmessage = null
      }, 3000);
      this.workExperience = employeeExperience;

   }
}
