import {inject,bindingMode,bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules,validateTrigger} from 'aurelia-validation';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Scrollbar from 'smooth-scrollbar';
import numeral from 'numeral';

@inject(DialogController, ProfileService, JwtService, ValidationControllerFactory, Element)
export class Editworkexperience {
   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) FromDate;
   @bindable({
      defaultBindingMode: bindingMode.twoWay
   }) ToDate;
   FromDate = null;
   ToDate = null;
   dateDifference = null;

   organization = false; // set the default value of the checkbox to unchecked
   experience = {
      Employer: 'Kensium Solutions Pvt Ltd' // sample default value
   };
   Employer = '';
   PersonWorkExperienceID;
   isEmployerDisabled = false;
   fromerrorMessage = false;
   differenceError = false;
   overlap = false;
   startctcCode = '1';
   endctcCode = '1';
    static inject = [Element];
    isDragging = false;
    dialog;
   constructor(controller, profileService, JwtService, controllerFactory, element) {
      this.controller = controller;
      this.profileService = profileService;
      this.JwtService = JwtService;
      this.controllerFactory = controllerFactory.createForCurrentScope();
      this.controllerFactory.validateTrigger = validateTrigger.change; 
      this.element = element;
      this.FromDate = null;
      this.ToDate = null;
      this.dateDifference = '';

      this.EmployeeID = this.JwtService.getEmployeeId();

      this.LocationId = this.JwtService.getLocationId();

      ValidationRules.customRule(
        'dateError',
        () =>{
        if(this.differenceError === true){
          return false;
        }else{
          return true;
        }
      },
      'From date should be less than to date.'
      );


      ValidationRules
         .ensure('employerValue').required().withMessage('Employer is required')
         .ensure('JobTitle').required().withMessage('Job title is required')
         .ensure('ToDate')
         .satisfiesRule('dateError')
         .on(this);
   }


   activate(experience) {
      this.PersonWorkExperienceID = experience.PersonWorkExperienceID;
      this.employerValue = experience.Employer;
      if (this.employerValue == this.experience.Employer) {
         this.organization = true;
         this.isEmployerDisabled = true;
      }
      this.FromDate = experience.FromDate;
      this.ToDate = experience.ToDate;
      this.JobTitle = experience.JobTitle;
      this.Joblocation = experience.Joblocation;
      this.StartingCTC = experience.StartingCTC;
      this.EndCTC = experience.EndCTC;
      this.Comments = experience.Comments;
      this.startctcCode = experience.CurrencyCode;
      this.StartDate = experience.StartDate;
      this.EndDate = experience.EndDate;
   }

   attached() {
       this.specializationInput.focus();
      Scrollbar.init(document.querySelector('.smooth-scrollbar'));
      flatpickr(this.FromdatePicker, {
         closeOnSelect: true,
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate:'today', // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.FromDate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         onClose: function (selectedDates, dateStr, instance) {
            this.FromDate = dateStr;
         }
      });
      this.FromcalendarIcon.addEventListener('click', () => {
         this.FromdatePicker._flatpickr.open();
      });

      flatpickr(this.TodatePicker, {
         closeOnSelect: true,
         dateFormat: 'd-m-Y', // the date format (default is 'Y-m-d')
         minDate: null, // the minimum selectable date in the calendar (default is null, which means no limit)
         maxDate:'today', // the maximum selectable date in the calendar (default is null, which means no limit)
         defaultDate: this.ToDate, // the initial date that should be shown in the calendar (default is null, which means today's date)
         enableTime: false, // whether to allow selecting time in addition to date
         enableSeconds: false, // whether to allow selecting seconds in addition to time
         time_24hr: false, // whether to use a 24-hour clock for time input
         minuteIncrement: 1, // the increment for minutes in the time input
         hourIncrement: 1, // the increment for hours in the time input
         timeFormat: 'h:i K', // the format for the time input (default is 'h:i K' for 12-hour clock and 'H:i' for 24-hour clock)
         onClose: function (selectedDates, dateStr, instance) {
            this.ToDate = dateStr;
         }
      });
      this.TocalendarIcon.addEventListener('click', () => {
         this.TodatePicker._flatpickr.open();
      });
      this.calculateDateDifference();
   }

   toggleEmployer() {
      this.isEmployerActive = this.organization;
      if (this.organization == true) {
         this.employerValue = this.experience.Employer;
         this.isEmployerDisabled = true;
      } else if (this.organization == false) {
         this.employerValue = '';
         this.isEmployerDisabled = false;
      }
   }

   calculateWorkexperience(fromdate,todate){
    this.profileService.GetExperience(fromdate,todate).then(data => {
      this.displayYears = data.Years;
      this.displayMonth = data.Months;
      this.displayDays = data.Days;
      this.dateDifference = this.displayYears + ' ' + 'Years(s)' + ' ' + this.displayMonth + ' ' + 'Months(s)' + ' ' + this.displayDays  + ' ' + 'Day(s)';
    })
   }

calculateDateDifference() {
  this.showExperience = true;
  if (this.FromDate && this.ToDate) {
    const fromParts = this.FromDate.split('-');
    const toParts = this.ToDate.split('-');
    this.calculateWorkexperience(this.FromDate,this.ToDate);
    // Create Date objects using the date parts
    const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
    const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
    const differenceInTime = toDate.getTime() - fromDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    if(fromDate >= toDate){
      this.differenceError = true;
      this.showExperience = false;
    }else if(fromDate < toDate){
      const CheckpreviousExperience = {
        EmployeeID : this.EmployeeID,
        StartDate : this.FromDate,
        EndDate : this.ToDate,
        PersonWorkExperienceID : 0,
      };
      this.differenceError = false;
      this.profileService.GetPreviousWorkExperience(CheckpreviousExperience)
      .then(data => {
         if (data >= 1){
             this.overlappingMessage = "From and to dates are overlapping . Do you want to save work experience ?";
             //setTimeout(() => {this.overlappingMessage = null}, 5000);
         }else{
          this.overlappingMessage = '';
         }
     });
    }
    else{
      this.differenceError = false;
    }
}else {
    this.dateDifference = null;
  }
}

formatStartingCTC() {
  const number = parseFloat(this.StartingCTC);
  // Check if the input is a valid number
  if (!isNaN(number)) {
    this.StartingCTC = numeral(number).format('0,0.00');
  }
}

formatEndingCTC() {
  const number = parseFloat(this.EndCTC);
  // Check if the input is a valid number
  if (!isNaN(number)) {
    this.EndCTC = numeral(number).format('0,0.00');
  }
}

  handleKeyDown(event) {
    if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          return false;
      }
      return true;
  }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    validateField(data){
      if(data)
      {
         this.controllerFactory.clear();
         //return;
      }
      ValidationRules
         .ensure('employerValue').required().withMessage('Employer is required')
         .ensure('JobTitle').required().withMessage('Job title is required')
         .ensure('ToDate')
         .satisfiesRule('dateError')
         .on(this.controllerFactory);
   }
   saveExperience() {
      const refreshEvent = new CustomEvent('refreshEditData');
      this.errors = null;
      this.controllerFactory.validate()
         .then(result => {
            if (result.valid) {
               const experienceEditData = {
                  PersonWorkExperienceID: this.PersonWorkExperienceID,
                  EmployeeID: this.EmployeeID,
                  Employer: this.employerValue,
                  FromDate: this.FromDate,
                  ToDate: this.ToDate,
                  JobTitle: this.JobTitle,
                  Joblocation: this.Joblocation,
                  StartingCTC: this.StartingCTC,
                  EndCTC: this.EndCTC,
                  Comments: this.Comments,
                  CurrencyCode : this.startctcCode,
               }
                  this.fromerrorMessage = false;
                  this.profileService.SaveWorkExperience(experienceEditData).then(() => {
                    this.controller.cancel();
  
                    this.profileService.GetWorkExperience(this.EmployeeID)
                       .then(() => {
                          document.dispatchEvent(refreshEvent); // Trigger the custom event
                       });
                 });
            }
         });     
   }

   cancel() {
      this.controller.cancel();
   }
}
